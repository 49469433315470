import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        specialties: [],
    },

    actions: {
        fetchAll({ commit, rootState, state }) {
            return state.specialties.length ? state.specialties : window.axios
                .get(rootState.auth.url + '/get-specialties')
                .then((res) => {
                    commit(types.SPECIALTIES, res.data);
                    return res.data;
                });
        },
    },

    mutations: {
        [types.SPECIALTIES](state, specialties) {
            state.specialties = specialties;
        },
    },
};
