import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		allStudents: [],
	},

	actions: {
		fetchAll({ commit, rootState }, { pageNumber, pageSize }) {
			return window.axios
				.get(rootState.auth.url + '/all-students', {
					params: {
						pagingParameter: { pageNumber, pageSize },
					}
				})
				.then((res) => {
					commit(types.STUDENTS, res.data);
					return res.data;
				});
		},
		fetchStudentsByCourse({ commit, rootState }, { courseId }) {
			return window.axios
				.get(rootState.auth.url + '/course-students', {
					params: { courseId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchStudentsByName({ rootState }, { studentName }) {
			return window.axios
				.get(rootState.auth.url + '/search-student', {
					params: { studentName },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchProfile({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-profile', {
					params: { studentId }
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchTests({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-tests', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchNotes({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-notes', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchManagerNotes({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-manager-note', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchAttendance({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-attendance', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchFunds({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-funds', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchSchedule({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-schedule', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchMessages({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/student-messages', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		sendMessage(
			{ rootState },
			{ StudentId, StudentUserName, CourseName, message },
		) {
			return window.axios
				.post(rootState.auth.url + '/send-message', {
					CenterId: rootState.auth.centerId,
					Content: message,
					StudentId,
					StudentUserName,
					CourseName,
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchStatics({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/get-students-statics', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchAllSubjects({ rootState }, { studentId }) {
			return window.axios
				.get(rootState.auth.url + '/get-students-subject-statics', {
					params: { studentId },
				})
				.then((res) => {
					return res.data;
				});
		},
		fetchSubjectStatics({ rootState }, { studentId, subjectId }) {
			return window.axios
				.get(rootState.auth.url + '/get-subject-statics', {
					params: { studentId, subjectId },
				})
				.then((res) => {
					return res.data;
				});
		},
	},

	mutations: {
		[types.STUDENTS](state, students) {
			state.allStudents = students.Students;
		},
	},
};
