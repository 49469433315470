<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<div v-else>
		<v-row :no-gutters="$vuetify.breakpoint.mobile">
			<!-- profile section -->
			<v-col
				cols="12"
				lg="3">
				<div class="text-center">
					<v-btn
						class="mb-sm-2 mb-0"
						text
						:class="{ 'pt-4': !$vuetify.breakpoint.mobile }"
						@click="
							dialog = true;
							fetchTeachers();
						">
						<v-icon left>mdi-magnify</v-icon>
						بحث عن مدرس
					</v-btn>

					<div>
						<h4 class="text-h4 mb-4">
							{{ teacherProfile.Teacher.Accounts.FirstName }}
							{{ teacherProfile.Teacher.Accounts.LastName }}
						</h4>
						<h5
							class="text-body-2 text--secondary mb-4"
							v-if="teacherProfile.Subject.length">
							<div
								class="mb-1"
								v-for="(subject, i) in teacherProfile.Subject"
								:key="i">
								{{ subject }}
							</div>
						</h5>
					</div>
				</div>
			</v-col>

			<v-divider
				vertical
				v-if="!$vuetify.breakpoint.mobile"></v-divider>
			<!-- main section -->
			<v-col
				cols="12"
				lg="9">
				<!-- tabs -->
				<div class="d-flex flex-wrap justify-start align-center pt-3">
					<!-- profile -->
					<div
						@click="tab = 'profile'"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'profile' }">
						معلومات المدرس
					</div>

					<!-- courses -->
					<div
						@click="
							tab = 'course';
							fetchCourses();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'course' }">
						شعب يدرسها
					</div>

					<!-- schedule -->
					<div
						@click="
							tab = 'workingHours';
							fetchSchedule();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'workingHours' }">
						برنامج الدوام
					</div>

					<!-- tests -->
					<div
						@click="
							tab = 'tests';
							fetchTests();
							fetchCourses();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'tests' }">
						الاختبارات
					</div>

					<!-- funds -->
					<div
						@click="
							tab = 'finance';
							fetchFunds();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'finance' }">
						سجل مالي
					</div>

					<!-- notes -->
					<div
						@click="
							tab = 'notes';
							fetchNotes();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'notes' }">
						ملاحظات
					</div>
				</div>
				<v-divider></v-divider>
				<v-tabs-items
					v-model="tab"
					class="transparent"
					touchless>
					<!-- profile -->
					<v-tab-item
						value="profile"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6 pa-4">
							<v-row>
								<v-col
									class="text-body-2"
									cols="6">
									العنوان
								</v-col>
								<v-col
									class="text-body-2 font-weight-bold"
									cols="6">
									{{ teacherProfile.Address | isAvailable }}
								</v-col>
								<v-col
									class="text-body-2"
									cols="6">
									تاريح الميلاد
								</v-col>
								<v-col
									class="text-body-2 font-weight-bold"
									cols="6">
									{{
										moment(teacherProfile.BirthDate).format(
											'YYYY/MM/DD',
										)
											| isAvailable(
												!!teacherProfile.BirthDate,
											)
									}}
								</v-col>
								<v-col
									class="text-body-2"
									cols="6">
									رقم الهاتف
								</v-col>
								<v-col
									class="text-body-2 font-weight-bold"
									cols="6">
									{{ teacherProfile.Phone | isAvailable }}
								</v-col>
							</v-row>
						</v-card>
					</v-tab-item>

					<!-- courses -->
					<v-tab-item
						value="course"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="coursesHeader"
								:items="coursesItems || []"
								:loading="loadingTabs"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 200px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- course -->
								<template v-slot:item.courses="{ item }">
									{{ item.Courses.CourseName }}
								</template>

								<!-- specialties -->
								<template v-slot:item.specialties="{ item }">
									{{ item.Specialties.SpecialtyName }}
								</template>

								<!-- actions -->
								<template v-slot:item.action="{ item }">
									<div class="actions">
										<v-btn
											:to="{
												name: 'course',
												params: { id: item.Courses.Id },
											}"
											title="الشعبة"
											depressed
											class="actions-btn myOrangeBg">
											<v-icon class="myOrange--text">
												mdi-bookshelf
											</v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- schedule -->
					<v-tab-item
						value="workingHours"
						class="pb-1">
						<div
							class="d-flex align-center justify-center"
							style="height: 50vh"
							v-if="loadingTabs">
							<v-progress-circular indeterminate>
							</v-progress-circular>
						</div>
						<div
							v-else
							class="schedule-container"
							dir="ltr">
							<div
								class="schedule-container__inner"
								dir="rtl">
								<working-hours
									:data="scheduleItems"></working-hours>
							</div>
						</div>
					</v-tab-item>

					<!-- testes -->
					<v-tab-item
						value="tests"
						class="pb-1">
						<!-- filter form -->
						<v-form class="mt-2">
							<v-row
								dense
								class="ma-0"
								align="center">
								<!-- test specialty -->
								<v-col
									cols="6"
									sm="3">
									<v-autocomplete
										v-model="testSpecialty"
										:items="specialtiesItems"
										:loading="loadingTestCourses"
										:disabled="loadingTestCourses"
										item-text="SpecialtyName"
										item-value="Id"
										label="الصف"
										outlined
										dense
										hide-details
										clearable
										:menu-props="{ offsetY: true }"
										class="rounded-lg"></v-autocomplete>
								</v-col>
								<!-- test course -->
								<v-col
									cols="6"
									sm="3">
									<v-autocomplete
										v-model="testCourse"
										:items="
											coursesItems?.filter((e) => {
												return testSpecialty
													? e.Specialties.Id ==
															testSpecialty
													: true;
											})
										"
										item-text="Courses.CourseName"
										item-value="Courses.Id"
										:loading="loadingTestCourses"
										:disabled="loadingTestCourses"
										label="الشعبة"
										outlined
										dense
										hide-details
										clearable
										:menu-props="{ offsetY: true }"
										class="rounded-lg"></v-autocomplete>
								</v-col>
								<!-- test detail -->
								<v-col
									cols="12"
									sm="3">
									<v-text-field
										v-model="testDetail"
										:loading="loadingTestCourses"
										:disabled="loadingTestCourses"
										label="محتوى الاختبار"
										outlined
										dense
										hide-details
										append-icon="mdi-magnify"
										class="rounded-lg"></v-text-field>
								</v-col>
								<!-- form button -->
								<v-col
									cols="12"
									sm="3">
									<v-btn
										:disabled="
											loadingTests || loadingTestCourses
										"
										:loading="
											loadingTests || loadingTestCourses
										"
										color="primary"
										@click="filteredTests">
										بحث
									</v-btn>
								</v-col>
							</v-row>
						</v-form>

						<!-- table -->
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-2">
							<v-data-table
								:headers="testsHeader"
								:items="testsArr || testsItems"
								:loading="loadingTabs || loadingTests"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 250px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- specialty -->
								<template v-slot:item.specialty="{ item }">
									<span>{{
										item.Specialty.SpecialtyName
									}}</span>

									<span v-if="$vuetify.breakpoint.mobile">
										- {{ item.Course.CourseName }}
									</span>
								</template>

								<!-- course -->
								<template
									v-slot:item.Course.CourseName="{ item }">
									<span>{{ item.Course.CourseName }}</span>
								</template>

								<!-- details -->
								<template v-slot:item.details="{ item }">
									<span>{{ item.Test.Details }}</span>
									<span v-if="$vuetify.breakpoint.mobile">
										({{ item.Test.TestType }})
									</span>
								</template>

								<!-- type -->
								<template v-slot:item.type="{ item }">
									<span>{{ item.Test.TestType }}</span>
								</template>

								<!-- done -->
								<template v-slot:item.done="{ item }">
									<div
										class="actions"
										v-if="item.Test.Done">
										<v-btn
											:to="{
												name: 'grades',
												params: { id: item.Test.Id },
											}"
											title="العلامات"
											depressed
											class="actions-btn myGreenBg">
											<v-icon class="myGreen--text">
												mdi-note-check
											</v-icon>
										</v-btn>
									</div>
									<div v-else>-</div>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- funds -->
					<v-tab-item
						value="finance"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="fundsHeader"
								:items="
									fundsItems.filter(
										(e) => e.InAmount || e.OutAmount,
									) || []
								"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showFinanceExpander"
								:expanded.sync="financeExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 200px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- out amount -->
								<template v-slot:item.OutAmount="{ item }">
									{{
										new Intl.NumberFormat().format(
											item.OutAmount,
										) | isAvailable(item.OutAmount)
									}}
								</template>

								<!-- in amount -->
								<template v-slot:item.InAmount="{ item }">
									{{
										new Intl.NumberFormat().format(
											item.InAmount,
										) | isAvailable(item.InAmount)
									}}
								</template>

								<!-- date -->
								<template v-slot:item.Date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>

								<!-- expander (details) -->
								<template
									v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											<strong v-if="item.Details">
												{{ item.Details }}
												<br />
											</strong>
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- notes -->
					<v-tab-item
						value="notes"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="notesHeader"
								:items="notesItems || []"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showNotesExpander"
								:expanded.sync="notesExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 200px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- date -->
								<template v-slot:item.Date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>

								<!-- expander {note} -->
								<template
									v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											{{ item.Note }}
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>

		<!-- search dialog -->
		<v-dialog
			v-model="dialog"
			width="600">
			<v-card
				class="pa-5"
				rounded="lg">
				<v-row dense>
					<!-- teacher name -->
					<v-col
						cols="12"
						sm="5">
						<v-text-field
							v-model="searchTeacherName"
							label="اسم المدرس"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"></v-text-field>
					</v-col>

					<!-- teacher degree -->
					<v-col
						cols="12"
						sm="5">
						<v-autocomplete
							v-model="searchTeacherDegree"
							:items="degrees"
							item-value="Id"
							item-text="Name"
							label="الاختصاص"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg">
						</v-autocomplete>
					</v-col>
					<!-- button -->
					<v-col
						cols="12"
						sm="2">
						<v-btn
							:disabled="loadingTeachers"
							:loading="loadingTeachers"
							color="primary"
							@click="filterTeachers">
							بحث
						</v-btn>
					</v-col>
				</v-row>
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<v-data-table
						:headers="searchHeader"
						:items="teacherArr || teachers"
						:loading="loadingTeachers"
						@click:row="changeTeacher"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="60vh"
						fixed-header
						mobile-breakpoint="0">
						<!-- name template -->
						<template v-slot:item.name="{ item }">
							{{ item.Teacher.Accounts.FirstName }}
							{{ item.Teacher.Accounts.LastName }}
						</template>
						<!-- subject template -->
						<template v-slot:item.degree="{ item }">
							{{ item.Degree.Name }}
						</template>
					</v-data-table>
				</v-card>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import WorkingHours from '@/components/WorkingHours.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'TeacherView',

	components: {
		WorkingHours,
	},

	data() {
		return {
			tab: 'profile',
			dialog: false,
			loading: true,
			loadingTabs: false,
			loadingTeachers: false,
			loadingTestCourses: false,

			testSpecialty: null,
			testCourse: null,
			testDetail: null,
			loadingTests: false,
			testsArr: null,

			coursesHeader: [
				{
					text: 'الصف',
					value: 'specialties',
					align: 'start',
					sortable: false,
				},
				{
					text: 'الشعبة',
					value: 'courses',
					align: 'start',
					sortable: false,
				},
				{ text: 'عدد الطلاب', value: 'StudentsCount', align: 'start' },
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
				},
			],

			coursesItems: [],
			testsItems: [],
			specialtiesItems: [],
			fundsItems: [],
			notesItems: [],
			scheduleItems: [],

			financeExpanded: [],
			notesExpanded: [],

			searchTeacherName: null,
			searchTeacherDegree: null,
			searchHeader: [
				{
					text: 'اسم المدرس',
					value: 'name',
					align: 'start',
					sortable: false,
				},
				{
					text: 'الاختصاص',
					value: 'degree',
					align: 'start',
					sortable: false,
				},
			],
			loadingTeachers: false,
			teacherArr: null,
		};
	},

	computed: {
		testsHeader() {
			const testsHeader = [
				{
					text: 'الصف',
					value: 'specialty',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'الشعبة',
					value: 'Course.CourseName',
					sortable: false,
				},
				{
					text: 'محتوى الاختبار',
					value: 'details',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'نوع الاختبار',
					value: 'type',
					sortable: false,
				},

				{
					text: 'مستلمة',
					value: 'done',
					align: 'center',
					sortable: false,
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return testsHeader.filter((e) => e.showInMobile);
			}
			return testsHeader;
		},
		fundsHeader() {
			const fundsHeader = [
				{
					text: 'له',
					value: 'InAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'مدفوع',
					value: 'OutAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'تفاصيل',
					value: 'Details',
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return fundsHeader.filter((e) => e.showInMobile);
			}
			return fundsHeader;
		},
		notesHeader() {
			const notesHeader = [
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{
					text: 'النوع',
					value: 'NoteType',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'الملاحظة',
					value: 'Note',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return notesHeader.filter((e) => e.showInMobile);
			}
			return notesHeader;
		},

		...mapState({
			teachers: (state) => state.teacher.teachers,
			degrees: (state) => state.teacher.degrees,
		}),
	},

	methods: {
		fetchProfile() {
			this.loading = true;
			this.$store
				.dispatch('teacher/fetchProfile', {
					teacherId: this.$route.params.id,
				})
				.then((res) => {
					this.teacherProfile = res;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchCourses() {
			this.loadingTestCourses = true;
			this.$store
				.dispatch('teacher/fetchCourses', {
					teacherId: this.$route.params.id,
				})
				.then((res) => {
					this.coursesItems = res;
					this.coursesItems?.forEach((e) => {
						if (
							!this.specialtiesItems.find(
								(s) => s.Id === e.Specialties.Id,
							)
						) {
							this.specialtiesItems.push(e.Specialties);
						}
					});
				})
				.finally(() => {
					this.loadingTestCourses = false;
				});
		},
		fetchTests() {
			this.loadingTabs = true;
			this.$store
				.dispatch('teacher/fetchTests', {
					teacherId: this.$route.params.id,
				})
				.then((res) => {
					this.testsItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchFunds() {
			this.loadingTabs = true;
			this.$store
				.dispatch('teacher/fetchFunds', {
					teacherId: this.$route.params.id,
				})
				.then((res) => {
					this.fundsItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchNotes() {
			this.loadingTabs = true;
			this.$store
				.dispatch('teacher/fetchNotes', {
					teacherId: this.$route.params.id,
				})
				.then((res) => {
					this.notesItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSchedule() {
			this.loadingTabs = true;
			this.$store
				.dispatch('teacher/fetchSchedule', {
					teacherId: this.$route.params.id,
				})
				.then((res) => {
					this.scheduleItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},

		fetchTeachers() {
			this.loadingTeachers = true;
			Promise.all([
				this.$store.dispatch('teacher/fetchAll'),
				this.$store.dispatch('teacher/fetchDegrees'),
			]).finally(() => {
				this.loadingTeachers = false;
			});
		},

		changeTeacher(item) {
			this.$router.replace({ params: { id: item.Teacher.Id } });
			this.fetchProfile();
			this.tab = 'profile';
			this.dialog = false;
		},

		filterTeachers() {
			this.loadingTeachers = true;
			setTimeout(() => {
				this.loadingTeachers = false;
				this.teacherArr = this.teachers
					.filter((e) => {
						if (!this.searchTeacherDegree) return true;
						return e.Degree.Id === this.searchTeacherDegree;
					})
					.filter((e) => {
						if (!this.searchTeacherName) return true;
						return (
							e.Teacher.Accounts.FirstName.includes(
								this.searchTeacherName,
							) ||
							e.Teacher.Accounts.FirstName.includes(
								this.searchTeacherName,
							)
						);
					});
			}, 500);
		},
		filteredTests() {
			this.loadingTests = true;
			setTimeout(() => {
				this.loadingTests = false;

				this.testsArr = this.testsItems
					.filter((e) => {
						if (!this.testSpecialty) return true;
						return e.Specialty.Id === this.testSpecialty;
					})
					.filter((e) => {
						if (!this.testCourse) return true;
						return e.Course.Id === this.testCourse;
					})
					.filter((e) => {
						if (!this.testDetail) return true;
						return e.Test.Details.includes(this.testDetail);
					});
			}, 500);
		},

		// style methods
		showFinanceExpander(item, event) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				// const indexExpanded = this.financeExpanded.findIndex((i) => i === item);
				// this.financeExpanded.splice(indexExpanded, 1);
				this.financeExpanded = [];
			} else {
				this.financeExpanded = [];
				this.financeExpanded.push(item);
			}
		},
		showNotesExpander(item, event) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.notesExpanded.findIndex(
					(i) => i === item,
				);
				this.notesExpanded.splice(indexExpanded, 1);
			} else {
				this.notesExpanded.push(item);
			}
		},
		moment,
	},

	mounted() {
		this.fetchProfile();
	},

	metaInfo: {
		title: 'Teacher',
		titleTemplate: '%s | Edunix',
	},
};
</script>
<style scoped></style>
