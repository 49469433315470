<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>
	<div v-else>
		<!-- filter form  -->
		<v-row
			class="ma-0"
			align="center"
			dense>
			<v-col
				cols="6"
				sm="4"
				lg="3">
				<v-select
					v-model="selectedSpecialtyId"
					@click:clear="
						selectedSpecialtyId = null;
						selectedCourseId = null;
					"
					:items="specialties"
					item-text="SpecialtyName"
					item-value="SpecialtyId"
					label="الصف"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
			<v-col
				cols="6"
				sm="4"
				lg="3">
				<v-select
					v-model="selectedCourseId"
					@click:clear="selectedSpecialtyId = null"
					:disabled="!selectedSpecialtyId"
					:items="specialties.find((e) => e.SpecialtyId === selectedSpecialtyId)?.Courses"
					item-text="CourseName"
					item-value="Id"
					label="الشعبة"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
			<v-col
				cols="12"
				sm="4"
				lg="3">
				<v-text-field
					v-model="searchName"
					:disabled="selectedSpecialtyId && !selectedCourseId"
					label="اسم الطالب"
					outlined
					dense
					hide-details
					append-icon="mdi-magnify"
					class="rounded-lg"></v-text-field>
			</v-col>
			<v-col
				cols="auto"
				sm="3">
				<v-btn
					:disabled="loadingStudents || (selectedSpecialtyId && !selectedCourseId)"
					:loading="loadingStudents"
					color="primary"
					@click="search">
					بحث
				</v-btn>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<!-- main -->
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
			<v-data-table
				:loading="loadingStudents"
				:headers="studentsHeader"
				:items="studentsItem"
				:options.sync="options"
				:server-items-length="totalStudents"
				:footer-props="{
					'items-per-page-options': [10, 20, 50],
				}"
				:items-per-page="isAllStudent ? 20 : -1"
				:hide-default-footer="!isAllStudent"
				dense
				:height="tableHeight"
				fixed-header
				mobile-breakpoint="0">
				<!-- name template -->
				<template v-slot:item.Student.Account.FirstName="{ item }">
					<span>
						{{ item.Student.Account.FirstName }}
						{{ item.Student.Account.LastName }}
					</span>
				</template>

				<!-- specialty template -->
				<template v-slot:item.specialty="{ item }">
					<span>{{ item.Specialty.SpecialtyName }}</span>
					<span v-if="$vuetify.breakpoint.mobile"> - {{ item.Courses.CourseName }}</span>
				</template>

				<!-- actions template -->
				<template v-slot:item.action="{ item }">
					<div class="actions">
						<div class="d-flex justify-end">
							<v-btn
								:to="{
									name: 'course',
									params: {
										id: item.CourseId,
									},
								}"
								exact
								title="الشعبة"
								class="actions-btn myOrangeBg"
								depressed>
								<v-icon class="myOrange--text"> mdi-bookshelf </v-icon>
							</v-btn>
							<v-btn
								:to="{
									name: 'student',
									params: { id: item.Student.Id },
									query: {
										courseId: selectedCourseId,
										specialtyId: selectedSpecialtyId,
									},
								}"
								exact
								title="الملف الشخصي للطالب"
								class="actions-btn myBlueBg"
								depressed>
								<v-icon class="myBlue--text"> mdi-account </v-icon>
							</v-btn>
						</div>
					</div>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'AllStudents',
	data() {
		return {
			selectedSpecialtyId: null,
			selectedCourseId: null,
			searchName: null,

			loading: true,
			loadingStudents: false,
			searched: false,

			students: [],

			isAllStudent: true,
			totalStudents: 0,
			options: {},
		};
	},

	watch: {
		options: {
			handler() {
				this.fetchAllStudents(this.options.page, this.options.itemsPerPage);
			},
			deep: true,
		},
	},

	computed: {
		tableHeight() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return `calc(100vh - ${this.isAllStudent ? 230 : 180}px)`;
			} else if (this.$vuetify.breakpoint.smAndUp) {
				return `calc(100vh - ${this.isAllStudent ? 250 : 200}px)`;
			} else if (this.$vuetify.breakpoint.width > 530) {
				return `calc(100vh - ${this.isAllStudent ? 280 : 230}px)`;
			} else {
				return `calc(100vh - ${this.isAllStudent ? 310 : 180}px)`;
			}
		},

		studentsItem() {
			return this.searched ? this.students : this.allStudents;
		},

		studentsHeader() {
			const studentsHeader = [
				{
					text: 'اسم الطالب',
					value: 'Student.Account.FirstName',
					align: 'start',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'الصف',
					value: 'specialty',
					align: 'start',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'الشعبة',
					value: 'Courses.CourseName',
					align: 'start',
					sortable: false,
				},
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) return studentsHeader.filter((e) => e.showInMobile);
			return studentsHeader;
		},

		...mapState({
			specialties: (state) => state.specialties.specialties,
			allStudents: (state) => state.student.allStudents,
		}),
	},

	methods: {
		search() {
			if (this.selectedCourseId) this.fetchStudentsCourse();
			else if (this.searchName) this.fetchStudentsByName();
			else {
				this.selectedCourseId = null;
				this.selectedSpecialtyId = null;
				this.searchName = null;
				this.searched = false;
				this.isAllStudent = true;
			}
		},
		fetchStudentsCourse() {
			this.loadingStudents = true;
			this.isAllStudent = false;
			this.$store
				.dispatch('student/fetchStudentsByCourse', {
					courseId: this.selectedCourseId,
				})
				.then((res) => {
					this.students = res;
					if (this.searchName) {
						this.students = this.students.filter((e) => {
							return (
								e.Student.Account.FirstName.includes(this.searchName) ||
								e.Student.Account.LastName.includes(this.searchName)
							);
						});
					}
				})
				.finally(() => {
					this.searched = true;
					this.loadingStudents = false;
				});
		},
		fetchStudentsByName() {
			this.loadingStudents = true;
			this.isAllStudent = false;
			this.$store
				.dispatch('student/fetchStudentsByName', {
					studentName: this.searchName,
				})
				.then((res) => {
					this.students = res;
				})
				.finally(() => {
					this.searched = true;
					this.loadingStudents = false;
				});
		},
		fetchSpecialties() {
			this.loading = true;
			this.$store.dispatch('specialties/fetchAll').finally(() => {
				this.loading = false;
			});
		},
		fetchAllStudents(pageNumber, pageSize) {
			this.isAllStudent = true;
			this.loadingStudents = true;
			this.$store
				.dispatch('student/fetchAll', {
					pageNumber: pageNumber,
					pageSize: pageSize,
				})
				.then((res) => {
					this.totalStudents = res.TotalCount;
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
	},

	created() {
		this.fetchSpecialties();
		this.fetchAllStudents(1, 20);
	},

	metaInfo: {
		title: 'All students',
		titleTemplate: '%s | Edunix',
	},
};
</script>
