<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<div v-else>
		<!-- tabs -->
		<div class="d-flex flex-wrap justify-start align-center pt-3">
			<div
				@click="tab = 'students'"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'students' }">
				الطلاب
			</div>
			<div
				@click="
					tab = 'tests';
					fetchTests();
					fetchSubjects();
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'tests' }">
				الاختبارات
			</div>
			<div
				@click="
					tab = 'workingHours';
					fetchSchedule();
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'workingHours' }">
				برنامج الدوام
			</div>
		</div>
		<v-divider></v-divider>

		<!-- main -->
		<v-tabs-items
			v-model="tab"
			class="transparent"
			touchless>
			<!-- students -->
			<v-tab-item
				value="students"
				class="pb-1">
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<v-data-table
						:headers="studentsHeader"
						:items="studentsItem"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 170px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- name -->
						<template v-slot:item.name="{ item }">
							{{ item.Student.Account.FirstName }}
							{{ item.Student.Account.LastName }}
						</template>
						<!-- attendance -->
						<template v-slot:item.attendance="{ item }">
							{{ item.StudentAttend.StudentRate }} \
							{{ item.StudentAttend.TotalRate }}
						</template>
						<!-- average -->
						<template v-slot:item.Average="{ item }">
							{{
								!item.Average || item.Average === 'NaN%'
									? '-'
									: item.Average
							}}
						</template>
						<!-- actions -->
						<template v-slot:item.action="{ item }">
							<div class="actions">
								<div>
									<v-btn
										:to="{
											name: 'student',
											params: {
												id: item.Student.Id,
											},
										}"
										title="الملف الشخصي للطالب"
										exact
										depressed
										class="actions-btn myBlueBg">
										<v-icon class="myBlue--text">
											mdi-account
										</v-icon>
									</v-btn>
								</div>
							</div>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>
			<!-- tests -->
			<v-tab-item
				value="tests"
				class="pb-1">
				<!-- details input -->
				<v-row
					dense
					class="my-2">
					<v-col
						cols="12"
						sm="6"
						md="4">
						<v-autocomplete
							v-model="testSubjectId"
							:items="subjects"
							item-text="Name"
							item-value="Id"
							:loading="loadingTestsSubjects"
							:disabled="loadingTestsSubjects"
							label="المادة"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-autocomplete>
					</v-col>
					<v-col
						cols="12"
						sm="6"
						md="4">
						<v-text-field
							v-model="testDetails"
							:loading="loadingTestsSubjects"
							:disabled="loadingTestsSubjects"
							label="محتوى الاختبار"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"></v-text-field>
					</v-col>
					<!-- form button -->
					<v-col
						cols="12"
						md="2">
						<v-btn
							:disabled="loadingTests || loadingTestsSubjects"
							:loading="loadingTests || loadingTestsSubjects"
							color="primary"
							@click="filteredTests">
							بحث
						</v-btn>
					</v-col>
				</v-row>

				<!-- table -->
				<v-card class="rounded-lg elevation-1 overflow-hidden">
					<v-data-table
						:headers="testsHeader"
						:items="testsArr || testsItems"
						:loading="loadingTabs || loadingTests"
						item-key="Id"
						@click:row="showTestsExpander"
						:expanded.sync="testsExpanded"
						:show-expand="$vuetify.breakpoint.mobile"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 220px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- date -->
						<template v-slot:item.Date="{ item }">
							{{ moment(item.Date).format('YYYY/MM/DD') }}
						</template>
						<!-- time -->
						<template v-slot:item.time="{ item }">
							{{
								Math.floor(
									moment(item.EndTime).diff(
										item.StartTime,
										'minutes',
									) / 60,
								) +
								':' +
								(moment(item.EndTime).diff(
									item.StartTime,
									'minutes',
								) %
									60)
							}}
						</template>
						<!-- subject name -->
						<template v-slot:item.SubjectName="{ item }">
							{{ item.SubjectName }}
							<span v-if="$vuetify.breakpoint.mobile">
								({{ item.TestType }})
							</span>
						</template>
						<!-- action -->
						<template v-slot:item.action="{ item }">
							<div
								class="actions"
								v-if="item.Done">
								<v-btn
									:to="{
										name: 'grades',
										params: { id: item.Id },
									}"
									title="العلامات"
									depressed
									class="actions-btn myGreenBg">
									<v-icon class="myGreen--text">
										mdi-note-check
									</v-icon>
								</v-btn>
							</div>
						</template>
						<!-- expander -->
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<div class="px-2 py-4">
									<strong v-if="item.Details">
										{{ item.Details }}
									</strong>
									<div>
										وقت الاختبار :
										{{
											Math.floor(
												moment(item.EndTime).diff(
													item.StartTime,
													'minutes',
												) / 60,
											) +
											':' +
											(moment(item.EndTime).diff(
												item.StartTime,
												'minutes',
											) %
												60)
										}}
									</div>
								</div>
							</td>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>
			<!-- working hours -->
			<v-tab-item
				value="workingHours"
				class="pb-1">
				<div
					class="d-flex align-center justify-center"
					style="height: 50vh"
					v-if="loadingTabs">
					<v-progress-circular indeterminate> </v-progress-circular>
				</div>
				<div
					v-else
					class="schedule-container"
					dir="ltr">
					<div
						class="schedule-container__inner"
						dir="rtl">
						<working-hours :data="scheduleItems"></working-hours>
					</div>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import WorkingHours from '@/components/WorkingHours.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'CourseView',
	components: {
		WorkingHours,
	},
	data() {
		return {
			loading: true,
			loadingTabs: false,
			loadingTestsSubjects: false,
			tab: 'students',

			studentsHeader: [
				{
					text: 'اسم الطالب',
					value: 'name',
					align: 'start',
					sortable: false,
				},
				{
					text: 'نسبة الدوام',
					value: 'attendance',
					align: 'center',
					sortable: false,
				},
				{ text: 'المعدل', value: 'Average', align: 'center' },
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
				},
			],
			studentsItem: [],
			testsItems: [],
			scheduleItems: [],

			testsArr: null,
			loadingTests: false,
			subjects: [],
			testSubjectId: null,
			testDetails: null,

			testsExpanded: [],
		};
	},
	computed: {
		testsHeader() {
			const testsHeader = [
				{
					text: 'المادة',
					value: 'SubjectName',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'محتوى الاختبار',
					value: 'Details',
					sortable: false,
				},
				{
					text: 'نوع الاختبار',
					value: 'TestType',
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{ text: 'الوقت', value: 'time', sortable: false },
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return testsHeader.filter((e) => e.showInMobile);
			}
			return testsHeader;
		},
	},
	methods: {
		fetchStudents() {
			this.loading = true;
			this.$store
				.dispatch('courses/fetchStudents', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.studentsItem = res;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchTests() {
			this.loadingTabs = true;
			this.$store
				.dispatch('courses/fetchTests', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.testsItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSubjects() {
			this.loadingTestsSubjects = true;
			this.$store
				.dispatch('courses/fetchSubjects', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.subjects = res;
				})
				.finally(() => {
					this.loadingTestsSubjects = false;
				});
		},
		fetchSchedule() {
			this.loadingTabs = true;
			this.$store
				.dispatch('courses/fetchSchedule', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.scheduleItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},

		filteredTests() {
			this.loadingTests = true;
			setTimeout(() => {
				this.loadingTests = false;

				this.testsArr = this.testsItems
					.filter((e) => {
						return this.testSubjectId
							? e.SubjectId === this.testSubjectId
							: true;
					})
					.filter((e) => {
						return this.testDetails
							? e.Details.includes(this.testDetails)
							: true;
					});
			}, 500);
		},

		// style methods
		showTestsExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.testsExpanded.findIndex(
					(i) => i === item,
				);
				this.testsExpanded.splice(indexExpanded, 1);
			} else {
				this.testsExpanded.push(item);
			}
		},
		moment,
	},

	mounted() {
		this.fetchStudents();
	},

	metaInfo: {
		title: 'Course',
		titleTemplate: '%s | Edunix',
	},
};
</script>

<style scoped></style>
