import HomeView from '../views/HomeView.vue'
import AllStudents from "../views/Students/AllStudents.vue"
import StudentView from "../views/Students/StudentView.vue"
import AllTeachers from "../views/Teachers/AllTeachers.vue"
import TeacherView from "../views/Teachers/TeacherView.vue"
import AllCourses from "../views/Courses/AllCourses.vue"
import CourseView from "../views/Courses/CourseView.vue"
import Grades from "../views/Grades/Grades.vue"
import LoginView from "../views/LoginView.vue"

export default [{
    path: '/',
    name: 'home',
    component: HomeView
},
{
    path: '/all-students',
    name: 'allStudents',
    component: AllStudents,
},
{
    path: '/student/:id',
    name: 'student',
    component: StudentView,
},
{
    path: '/all-teachers',
    name: 'allTeachers',
    component: AllTeachers,
},
{
    path: '/teacher/:id',
    name: 'teacher',
    component: TeacherView,
},
{
    path: '/all-courses',
    name: 'allCourses',
    component: AllCourses,
},
{
    path: '/course/:id',
    name: 'course',
    component: CourseView,
},
{
    path: '/grades/:id',
    name: 'grades',
    component: Grades,
},
{
    path: '/login',
    name: 'login',
    component: LoginView,
},

]