<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>
	<div v-else>
		<!-- filter form -->
		<v-row
			dense
			class="ma-0"
			align="center">
			<!-- specialties -->
			<v-col
				cols="12"
				sm="4"
				lg="3">
				<v-select
					v-model="selectedSpecialtyId"
					:items="specialties"
					item-text="SpecialtyName"
					item-value="SpecialtyId"
					label="الصف"
					clearable
					outlined
					dense
					hide-details
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
			<!-- gender -->
			<v-col cols="auto">
				<v-radio-group
					row
					dense
					hide-details
					class="text-h1 mt-0"
					v-model="selectedGender">
					<template v-slot:label>
						<strong>الجنس:</strong>
					</template>
					<v-radio
						:value="null"
						label="الكل">
					</v-radio>
					<v-radio
						:value="genders.male"
						label="ذكور">
					</v-radio>
					<v-radio
						:value="genders.female"
						label="إناث">
					</v-radio>
					<v-radio
						:value="genders.mixed"
						label="مختلطة">
					</v-radio>
				</v-radio-group>
			</v-col>
			<!-- form button -->
			<v-col
				cols="12"
				sm="auto"
				class="mr-0 mr-sm-3">
				<v-btn
					:disabled="loadingCourses"
					:loading="loadingCourses"
					color="primary"
					@click="filteredCourses">
					بحث
				</v-btn>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<!-- students table -->
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
			<v-data-table
				:headers="coursesHeader"
				:items="coursesArr || courses"
				dense
				:items-per-page="-1"
				hide-default-footer
				height="calc(100vh - 170px)"
				fixed-header
				mobile-breakpoint="0">
				<!-- SpecialtyName -->
				<template v-slot:item.SpecialtyId="{ item }">
					{{ item.SpecialtyName }}
				</template>
				<!-- action -->
				<template v-slot:item.action="{ item }">
					<div class="actions mx-auto">
						<v-btn
							:to="{
								name: 'course',
								params: {
									id: item.Id,
								},
							}"
							class="actions-btn myOrangeBg"
							depressed
							title="عرض الشعبة">
							<v-icon class="myOrange--text">
								mdi-bookshelf
							</v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import { genders } from '@/helpers/enums';
import { mapState } from 'vuex';

export default {
	name: 'AllCourses',
	data() {
		return {
			loading: true,
			loadingCourses: false,

			coursesArr: null,

			selectedSpecialtyId: null,
			selectedGender: null,

			genders,

			coursesHeader: [
				{
					text: 'الصف',
					value: 'SpecialtyId',
					align: 'start',

					sortable: false,
				},
				{
					text: 'الشعبة',
					value: 'CourseName',
					align: 'start',
					sortable: false,
				},
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
				},
			],
		};
	},

	computed: {
		courses() {
			return this.specialties
				.map((e) =>
					e.Courses.map((c) => ({
						...c,
						SpecialtyName: e.SpecialtyName,
						SpecialtyId: e.SpecialtyId,
					})),
				)
				.flat();
		},

		...mapState({
			specialties: (state) => state.specialties.specialties,
		}),
	},

	methods: {
		filteredCourses() {
			this.loadingCourses = true;
			setTimeout(() => {
				this.coursesArr = this.courses
					.filter((e) => {
						return this.selectedGender
							? e.Gender === this.selectedGender
							: true;
					})
					.filter((e) => {
						return this.selectedSpecialtyId
							? e.SpecialtyId === this.selectedSpecialtyId
							: true;
					});
				this.loadingCourses = false;
			}, 500);
		},
		fetchCourses() {
			this.loading = true;
			this.$store.dispatch('specialties/fetchAll').finally(() => {
				this.loading = false;
			});
		},
	},

	mounted() {
		this.fetchCourses();
	},

	metaInfo: {
		title: 'All courses',
		titleTemplate: '%s | Edunix',
	},
};
</script>
