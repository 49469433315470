<template>
	<div>
		<h2 class="primary--text text-h5 mb-2">برنامج الدوام</h2>
		<v-row
			dense
			class="ma-0"
			align="center">
			<!-- specialties -->
			<v-col
				cols="12"
				sm="5">
				<v-select
					@click:clear="
						selectedSpecialty = null;
						selectedCourse = null;
					"
					v-model="selectedSpecialty"
					:items="specialties"
					item-text="SpecialtyName"
					item-value="Courses"
					label="الصف"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
			<!-- specialties -->
			<v-col
				cols="12"
				sm="5">
				<v-select
					@click:clear="selectedCourse = null"
					:disabled="!selectedSpecialty"
					v-model="selectedCourse"
					:items="selectedSpecialty || []"
					item-text="CourseName"
					item-value="Id"
					label="الشعبة"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
			<v-col
				cols="12"
				sm="2">
				<v-btn
					:disabled="!selectedCourse"
					color="primary"
					block
					@click="fetchSchedule">
					بحث
				</v-btn>
			</v-col>
		</v-row>
		<div
			class="d-flex align-center justify-center"
			style="height: 450px"
			v-if="loadingSchedule">
			<v-progress-circular indeterminate> </v-progress-circular>
		</div>
		<div
			v-else-if="scheduleItems?.length"
			class="schedule-container"
			dir="ltr">
			<v-divider class="my-2"></v-divider>
			<div
				class="schedule-container__inner"
				dir="rtl">
				<working-hours :data="scheduleItems"></working-hours>
			</div>
		</div>
		<div
			v-else-if="searched"
			class="d-flex align-center justify-center text--disabled"
			style="height: 450px">
			لم يتم تسجيل برنامج دوام لهذه الشعبة
		</div>
		<div
			v-else
			class="d-flex align-center justify-center text-h5 text--disabled"
			style="height: 450px">
			اختر احد الشعب
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import WorkingHours from './WorkingHours.vue';

export default {
	name: 'scheduleDialog',

	components: { WorkingHours },

	data() {
		return {
			loading: true,
			loadingSchedule: false,

			searched: false,

			selectedSpecialty: null,
			selectedCourse: null,

			scheduleItems: null,
		};
	},

	computed: {
		courses() {
			return this.specialties
				.map((e) =>
					e.Courses.map((c) => ({
						...c,
						SpecialtyName: e.SpecialtyName,
						SpecialtyId: e.SpecialtyId,
					})),
				)
				.flat();
		},

		...mapState({
			specialties: (state) => state.specialties.specialties,
		}),
	},

	methods: {
		fetchSchedule() {
			this.searched = false;
			this.loadingSchedule = true;
			this.$store
				.dispatch('courses/fetchSchedule', {
					courseId: this.selectedCourse,
				})
				.then((res) => {
					this.scheduleItems = res;
				})
				.finally(() => {
					this.searched = true;
					this.loadingSchedule = false;
				});
		},

		fetchCourses() {
			this.loading = true;
			this.$store.dispatch('specialties/fetchAll').finally(() => {
				this.loading = false;
			});
		},
	},

	mounted() {
		this.fetchCourses();
	},
};
</script>
