<template>
	<div>
		<h2 class="primary--text text-h5 mb-2">الحضور</h2>
		<div
			class="d-flex align-center justify-space-around mx-auto"
			style="width: 420px">
			<v-btn
				x-small
				fab
				elevation="0"
				@click="nextDay">
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
			<div
				@click="showDialog = true"
				class="primary pa-3 rounded-lg white--text text-center"
				style="width: 220px">
				{{ moment(day).format('YYYY/MM/DD') }}
			</div>
			<v-btn
				x-small
				fab
				elevation="0"
				@click="prevDay">
				<v-icon> mdi-arrow-left </v-icon>
			</v-btn>
		</div>
		<v-chip-group mandatory>
			<v-chip
				class="chipsColor"
				active-class="chipsColorActive"
				@click="isStudent = true">
				طلاب
			</v-chip>
			<v-chip
				class="chipsColor"
				active-class="chipsColorActive"
				@click="isStudent = false">
				مدرسون
			</v-chip>
		</v-chip-group>

		<v-divider class="my-2"></v-divider>
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
			<v-data-table
				:headers="header"
				:items="
					attendance[
						isStudent ? 'StudentAttendance' : 'TeacherAttendance'
					]?.filter((e) => !e.HasAttend || e.IsLate || e.IsEarlyLeave)
				"
				:loading="loadingAttendance"
				item-key="Id"
				dense
				:items-per-page="-1"
				hide-default-footer
				height="calc(100vh - 320px)"
				fixed-header
				mobile-breakpoint="0">
				<template v-slot:item.type="{ item }">
					{{
						item.HasAttend
							? item.IsLate
								? 'متاخر'
								: 'انصراف مبكر'
							: 'غائب'
					}}
					<span v-if="!isStudent && item.LateMinute">
						-
						{{ item.LateMinute }}
						د
					</span>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			v-model="showDialog"
			width="300px">
			<v-date-picker
				elevation="3"
				@change="changeDate"
				scrollable
				locale="ar-SY"></v-date-picker>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'AttendanceDialog',

	data() {
		return {
			loadingAttendance: false,

			attendance: [],
			isStudent: true,

			day: this.moment(),

			header: [
				{
					value: 'Account.FullName',
					text: 'الاسم',
				},
				{
					value: 'type',
					text: 'النوع',
				},
			],

			showDialog: false,
		};
	},

	methods: {
		changeDate(event) {
			this.day = this.moment(event);
			this.fetch();
			this.showDialog = false;
		},
		nextDay() {
			this.day = this.moment(this.day).add(1, 'days');
			this.fetch();
		},
		prevDay() {
			this.day = this.moment(this.day).add(-1, 'days');
			this.fetch();
		},

		fetch() {
			this.loadingAttendance = true;
			this.$store
				.dispatch('sideBar/fetchAttendance', {
					day: new Date(this.day).getTime(),
					// day: new Date(1692253800000).setHours(10, 2, 0, 0),
				})
				.then((res) => {
					this.attendance = res;
				})
				.finally(() => {
					this.loadingAttendance = false;
				});
		},
		moment,
	},

	mounted() {
		this.fetch();
	},
};
</script>
